(function(n,i,v,r,s,c,x,z){x=window.AwsRumClient={q:[],n:n,i:i,v:v,r:r,c:c};window[n]=function(c,p){x.q.push({c:c,p:p});};z=document.createElement('script');z.async=true;z.src=s;document.head.insertBefore(z,document.head.getElementsByTagName('script')[0]);})(
    'cwr',
    process.env.VUE_APP_RUM_APP_MONITOR_ID,
    '1.0.0',
    'eu-central-1',
    window.location.origin+process.env.BASE_URL+"js/cwr-1.14.0.js",
    {
        sessionSampleRate: 1,
        guestRoleArn: process.env.VUE_APP_IDENTITY_POOL_GUEST_ROLE_ARN,
        // guestRoleArn: "arn:aws:iam::459855211804:role/WaReviewToolAuthStack-WaReviewToolAdminAuthIdentit-15M48HJED8ILX",
        identityPoolId: process.env.VUE_APP_IDENTITY_POOL_ID,
        // identityPoolId: "eu-central-1:a1cdd5b5-e9c1-4ebb-b13d-e094cffb9faf",    
        endpoint: "https://dataplane.rum.eu-central-1.amazonaws.com",
        telemetries: ["errors","performance", 
        [ 'http', { addXRayTraceIdHeader: true } ] ],
        allowCookies: true,
        enableXRay: true,
        disableAutoPageView: true
    },
);




