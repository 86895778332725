// import './tracing'

import 'core-js/stable'
import Vue from 'vue'
import App from './App'
import router from './router'
import cognitoAuth from './cognito'

import CoreuiVue from '@coreui/vue'
import { iconsSet as icons } from './assets/icons/icons.js'
import rum from './rum.js'
import store from './store'
import "core-js/stable";
import "regenerator-runtime/runtime";
import VueQuillEditor from 'vue-quill-editor'
import jwtInterceptor  from './cognito/jwtInterceptor';
import Vuelidate from 'vuelidate'

import 'quill/dist/quill.core.css' // import styles
import 'quill/dist/quill.snow.css' // for snow theme
import 'quill/dist/quill.bubble.css' // for bubble theme


Vue.use(VueQuillEditor, /* { default global options } */);
Vue.use(require('vue-moment'));

Vue.use(Vuelidate)


Vue.prototype.$http = jwtInterceptor;
Vue.config.performance = true
Vue.use(CoreuiVue)
Vue.prototype.$log = console.log.bind(console)


new Vue({
  el: '#app',
  router,
  store,
  icons,
  rum,
  cognitoAuth,
  template: '<App/>',
  components: {
    App
  }
})
