import Vue from 'vue'
import Router from 'vue-router'
import cognitoAuth from '@/cognito'


// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views
const Home = () => import('@/views/Home')

// Views - Pages
const Page404 = () => import('@/views/pages/Page404')
const Page500 = () => import('@/views/pages/Page500')
const Login = () => import('@/views/pages/Login')
const Profile = () => import('@/views/pages/Profile')
const Readme = () => import('@/views/help/Readme')

const Reports = () => import('@/views/reports/Reports')
const Report = () => import('@/views/reports/Report')
const ReportCreate = () => import('@/views/reports/ReportCreate')

const RemediationItems = () => import('@/views/remediation_items/RemediationItems')
const RemediationItem = () => import('@/views/remediation_items/RemediationItem')
const RemediationItemCreate = () => import('@/views/remediation_items/RemediationItemCreate')
const RemediationItemDelete = () => import('@/views/remediation_items/RemediationItemDelete')

const Customers = () => import('@/views/customers/Customers')
const Customer = () => import('@/views/customers/Customer')
const CustomerCreate = () => import('@/views/customers/CustomerCreate')
const CustomerDelete = () => import('@/views/customers/CustomerDelete')

const Choices = () => import('@/views/choices/Choices')

const Improvements = () => import('@/views/improvements/Improvements')

const Workloads = () => import('@/views/workloads/Workloads')
const Workload = () => import('@/views/workloads/Workload')
const WorkloadCreate = () => import('@/views/workloads/WorkloadCreate')
const WorkloadDelete = () => import('@/views/workloads/WorkloadDelete')
const WorkloadUpdates = () => import('@/views/workloads/WorkloadUpdates')
const WorkloadsNotifications = () => import('@/views/workloads/WorkloadsNotifications')

Vue.use(Router)

function requireAuth (to, from, next) {
  cognitoAuth.isAuthenticated((result, loggedIn) => {   
    if (!loggedIn) {
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      })
    } else {
      next()
    }
  })
}

function logout(to, from, next) {
  cognitoAuth.logout()
  next('/')
}


export default new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})

function configRoutes () {
  return [
    {
      path: '/',
      redirect: '/home',
      beforeEnter: requireAuth,
      name: 'Home',
      component: TheContainer,
      children: [
        { 
          path: '/profile', 
          name: 'Profile', 
          component: Profile, 
        },
        {
          path: 'home',
          component: Home
        },
        {
          path: 'readme',
          name: 'README',
          component: Readme
        },        
        {
          path: 'reports',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '',
              name: 'Reports',
              component: Reports
            },
            {
              path: 'create',
              meta: {
                label: 'Create Report'
              },
              name: 'Create Report',
              component: ReportCreate
            },            
            {
              path: ':id',
              meta: {
                label: 'Report Details'
              },
              name: 'Report',
              component: Report
            }          
          ]
        },
        {
          path: 'remediation-items',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '',
              name: 'Remediation Items',
              component: RemediationItems
            },
            {
              path: 'create',
              meta: {
                label: 'Create Remediation Item'
              },
              name: 'Create Remediation Item',
              component: RemediationItemCreate
            },
            {
              path: ':id',
              meta: {
                label: 'Remediation Item Details'
              },
              name: 'Remediation Item',
              component: RemediationItem
            },
            {
              path: ':id/delete',
              meta: {
                label: 'Delete Remediation Item'
              },
              name: 'Delete Remediation Item',
              component: RemediationItemDelete
            }            
          ]
        },
        {
          path: 'customers',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '',
              name: 'Customers',
              component: Customers
            },
            {
              path: 'create',
              meta: {
                label: 'Create Customer'
              },
              name: 'Create Customer',
              component: CustomerCreate
            },            
            {
              path: ':id',
              meta: {
                label: 'Customer Details'
              },
              name: 'Customer',
              component: Customer
            },
            {
              path: ':id/delete',
              meta: {
                label: 'Delete Customer'
              },
              name: 'Delete Customer',
              component: CustomerDelete
            }
          ]
        },
        {
          path: 'choices',
          name: 'Choices',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: ':lense',
              component: Choices
            }
          ]
        },
        {
          path: 'improvements',
          name: 'Improvements',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: ':cloud',
              component: Improvements,
              children: [
                {
                  path: ':lens',
                  component: Improvements
                }
              ]
            }


          ]
        },        
        {
          path: 'workloads',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '',
              name: 'Workloads',
              component: Workloads
            },
            {
              path: 'create',
              meta: {
                label: 'Create Workload'
              },
              name: 'Create Workload',
              component: WorkloadCreate
            },
            {
              path: 'updates',
              meta: {
                label: 'Workloads Notifications'
              },
              name: 'View Workloads Notifications',
              component: WorkloadsNotifications
            },            
            {
              path: ':id',
              meta: {
                label: 'Workload Details'
              },              
              name: 'Workload',
              component: Workload
            },            
            {
              path: ':id/delete',
              meta: {
                label: 'Delete Workload'
              },
              name: 'Delete Workload',
              component: WorkloadDelete
            },
            {
              path: ':id/update',
              meta: {
                label: 'Update Workload Lens'
              },
              name: 'Update Workload Lens',
              component: WorkloadUpdates
            }                             
          ]
        },          
      ]
    },
    {
      path: '/pages',
      redirect: '/pages/404',
      name: 'Pages',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: '404',
          name: 'Page404',
          component: Page404
        },
        {
          path: '500',
          name: 'Page500',
          component: Page500
        },
       
        {
          path: '/login',
          name: 'Login',
          component: Login
        },  
        {
          path: '/logout',
          beforeEnter: logout, 
        },              
      ]
    }
  ]
}

