import axiosInstance from "@/services/api";
import cognitoAuth from '@/cognito'
 
axiosInstance.interceptors.request.use((config) => {
    // const token = localStorage.getItem('token');
    const token = localStorage.getItem('access_token');

    if (token == null) {
        return config;
    }

    config.headers.common["Authorization"] = `${token}`;
    return config;
});


axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        const originalConfig = error.config;
        console.log("Error API: "+ error)
            
        // Retry for CORS error (https://github.com/softonic/axios-retry/issues/138)
        if (error.message === 'Network Error' && !originalConfig._retry) {
            console.log("Network Error: "+ error.code)
            originalConfig._retry = true;
            
            await cognitoAuth.refreshTokenWrapper()
            
            // const token = localStorage.getItem('token');
            const token = localStorage.getItem('access_token');

            originalConfig.headers["Authorization" ] = `${token}`;
            
            return axiosInstance(originalConfig);            
        } 

        if (originalConfig.url !== "/login" && error.response) {
            if (error.response.status === 401 && !originalConfig._retry) {
                console.log(error.response.status)
                console.log("Error 401: "+originalConfig._retry)
                originalConfig._retry = true;
                
                await cognitoAuth.refreshTokenWrapper()
                
                // const token = localStorage.getItem('token');
                const token = localStorage.getItem('access_token');
    
                originalConfig.headers["Authorization" ] = `${token}`;
                
                return axiosInstance(originalConfig);
            }
            
        return Promise.reject(error);
          
        } else {
            return Promise.reject(error);
        }
    }
    );

export default axiosInstance;